<template>
  <div class="login">
    <vue-particles
      class="login-bg"
      color="#39AFFD"
      :particleOpacity="0.3"
      :particlesNumber="120"
      shapeType="circle"
      :particleSize="5"
      linesColor="#8DD1FE"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.3"
      :linesDistance="150"
      :moveSpeed="2"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    
    <div class="login-from"><!-- @click="enterFullScreen" -->
      <h1>智慧工地监管平台</h1>
      <div class="data">
        <input type="text" v-model="username" @keyup.enter="onLogin" />
        <input type="password" v-model="password" @keyup.enter="onLogin" />
      </div>
      <div :class="loading ? 'login-botton-loading' : 'login-botton'" @click="onLogin">登录{{loading ? '中' : ''}}</div>
    </div>
    <transition name="message">
      <div class="model" v-if="unsuccess">{{message}}</div>
    </transition>
  </div>
</template>

<style lang="less" scope>
@Width: 192rem;
.login {
  width: 1920 / @Width;
  height: 1080 / @Width;
  position: relative;
}

.login-bg {
  position: absolute;
  z-index: 1;
  width: 1920 / @Width;
  height: 1080 / @Width;
}
.login-from {
  position: absolute;
  z-index: 2;
  left: 650 / @Width;
  top: 350 / @Width;
  width: 620 / @Width;
  height: 380 / @Width;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 30 / @Width rgba(255, 255, 255, 0.5);
  border-radius: 20 / @Width;
  padding: 30 / @Width 100 / @Width;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-size: 30 / @Width;
    color: #00e4ff;
    // line-height: 20 / @Width;
    text-align: center;
  }
  .data {
    input {
      display: block;
      width: 100%;
      height: 40 / @Width;
      border-radius: 5 / @Width;
      margin: 20 / @Width 0;
      border: 1 / @Width solid rgba(255, 255, 255, 0.3);
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
      padding-left: 10 / @Width;
      font-size: 18 / @Width;
      box-sizing: border-box;
      box-shadow: 0 0 5 / @Width rgba(255, 255, 255, 0.3);
    }
  }

  .login-botton {
    margin-bottom: 20 / @Width;
    background-color: rgba(0, 228, 255, 0.4);
    text-align: center;
    height: 40 / @Width;
    font-size: 18 / @Width;
    line-height: 40 / @Width;
    border-radius: 5 / @Width;
  }
  .login-botton-loading {
    margin-bottom: 20 / @Width;
    background-color: rgba(255, 255, 255, 0.4);
    text-align: center;
    height: 40 / @Width;
    font-size: 18 / @Width;
    line-height: 40 / @Width;
    border-radius: 5 / @Width;
  }
}

.model {
    position: absolute;
    z-index: 3;
    left: 650 / @Width;
    top: 750 / @Width;
    width: 620 / @Width;
    height: 60 / @Width;
    background-color: rgba(255, 0, 0, 0.5);
    border-radius: 5 / @Width;
    text-align: center;
    line-height: 60/@Width;
}


// 登录状态动画
.message-enter-active {
  animation: message-in 0.5s;
}
.message-leave-active {
  animation: message-out 0.3s;
}
@keyframes message-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes message-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: '',
      password: '',
      loading: false,
      unsuccess: false,
      message: '',
      timer: null
    };
  },
  mounted() {
    localStorage.clear()
  },
  watch: {
    unsuccess: function (newVal, oldVal) {
      console.log('unsuccess =>', newVal)
      if (newVal) {
        this.timer = setTimeout(() => {
          this.unsuccess = false
        }, 3000);
      }
    }
  },
  methods: {
    // 发起登录
    onLogin: function () {
      var that = this
      if (this.loading) {
        return
      }
      this.unsuccess = false
      if(this.username == '') {
        this.message = "用户名不能为空"
        this.unsuccess = true
        return
      }
      if(this.password == '') {
        this.message = "密码不能为空"
        this.unsuccess = true
        return
      }
      this.loading = true
      var that = this
      var data = {
        username: this.username,
        password: this.password
      }
      this.$axiosAdmin({
        method: "post",
        url: "api/admin/user/login",
        data: JSON.stringify(data),
      })
        .then((res) => {
          console.log(res)
          if (res.code === 1) {
            that.unsuccess = true
            that.message = '认证失败，请重新登录'
            that.username = ''
            that.password = ''
            localStorage.removeItem('token')
          }
          if (res.code === 0) {
            that.unsuccess = false
            localStorage.setItem('username', that.username)
            localStorage.setItem('token', res.data.access_token)
            that.$router.push({name: 'Project'})
          }
          that.loading = false
        })
        .catch((err) => {
          console.log(err);
          that.loading = false
        });
    },
    // 进入全屏
    enterFullScreen: function () {
      let el = document.documentElement;
      let rfs =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
      if (rfs) {
        // typeof rfs != "undefined" && rfs
        rfs.call(el);
      } else if (typeof window.ActiveXObject !== "undefined") {
        // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    // 退出全屏
    exitFullScreen: function () {
      let el = document;
      let cfs =
        el.cancelFullScreen ||
        el.mozCancelFullScreen ||
        el.msExitFullscreen ||
        el.webkitExitFullscreen ||
        el.exitFullscreen;
      if (cfs) {
        // typeof cfs != "undefined" && cfs
        cfs.call(el);
      } else if (typeof window.ActiveXObject !== "undefined") {
        // for IE，这里和fullScreen相同，模拟按下F11键退出全屏
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
  },
};
</script>